import React from 'react'
import Testimonial from './Testimonial'
import clientOne from '../../../images/client-hair-1.avif'
import clientTwo from '../../../images/client-hair-2.avif'
import clientThree from '../../../images/client-hair-3.avif'
import clientFour from '../../../images/client-hair-4.avif'
import { StaticImage } from 'gatsby-plugin-image'

const testimonialOne = {
  clientMessage: `I have visited my favourite hair stylist Josie in her new premises at "The Little Hair Lounge" recently. She is an amazing stylist in every aspect of her profession. Josie listens to what you are trying to achieve in your hair style and advises appropriately to your needs. Her colour work and cutting is impeccable. I am an ex-hairdresser and have high expectations of what I desire from my hair stylist. Josie never fails me and I always come home happy with the result. I can not recommend highly enough Josie and her work, friendly and bubbly attitude. A very pleasant experience.`,
  clientImage: clientOne,
  clientName: 'Sue',
}

const testimonialTwo = {
  clientMessage: `I have been going to Josie for a while now after I decided to have a dramatic change, (long hair to pixie short) and I have never looked back! Josie actually listens to what you want and delivers. She is one brilliant stylist. Definitely recommend`,
  clientImage: clientFour,
  clientName: 'Felicia',
}

const testimonialThree = {
  clientMessage: `Wow I will never go to another hair dresser again. Josie has been doing my hair for 2 years now I always walk out very happy with her work. She likes to take her time never rushes. Also now being in her own salon her talents really come out. She is her own boss. She is amazing having worked in different quality salons in Australia and overseas. I can tell she loves what she does. Ask her about style or colour and straight away she know what you want and maybe has an idea to make what you want better... I will never go anywhere else having thick hair that frizzes. Josie knows what to do. She is just a very welcoming and lovely person as well.`,
  clientImage: clientTwo,
  clientName: 'Sue',
}

const testimonialFour = {
  clientMessage: `Josie is an amazing stylist. She has been doing my hair for quite a while now. I have never had an experience I haven't been satisfied with. If you're looking for a new hair dresser, contact her and book now. Aside from her mad cutting and colouring skills, a salon appointment with her is pampering. She is an absolutely positive, happy beautiful soul and you come away feeling happy with the results but also high on life. So get in and book an appointment, before word gets out about her new adventure and becomes high in demand. You will not regret it.`,
  clientImage: clientThree,
  clientName: 'Paula',
}

const CustomerTestimonialsCarousel = () => {
  return (
    <div className="background-colour-beige-3">
      <div className="container justify-content-center py-5">
        <StaticImage
          alt="client love heading"
          width={250}
          loading="lazy"
          className="testimonial-image-container"
          imgClassName="testimonials-title d-block"
          src={'../../../images/client-love.png'}
        ></StaticImage>
        <div
          id="testimonialCarousel"
          className="carousel testimonials slide carousel-dark"
          data-bs-ride="carousel"
          data-bs-interval="15000"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <Testimonial {...testimonialOne} />
            </div>

            <div className="carousel-item">
              <Testimonial {...testimonialTwo} />
            </div>

            <div className="carousel-item">
              <Testimonial {...testimonialThree} />
            </div>

            <div className="carousel-item">
              <Testimonial {...testimonialFour} />
            </div>
          </div>
          <button
            className="carousel-control-prev carousel-control"
            type="button"
            data-bs-target="#testimonialCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
          </button>
          <button
            className="carousel-control-next carousel-control"
            type="button"
            data-bs-target="#testimonialCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
          </button>
          <div className="carousel-indicators  mt-5 mb-2">
            <button
              type="button"
              data-bs-target="#testimonialCarousel"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#testimonialCarousel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#testimonialCarousel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#testimonialCarousel"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerTestimonialsCarousel
