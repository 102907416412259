import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Salon = () => {
  return (
    <div className="background-colour-beige-1">
      <div className="container py-5 text-center">
        <StaticImage
          alt="little hair lounge logo"
          placeholder="blurred"
          height={200}
          loading="lazy"
          src={'../../../images/lhl-logo-cropped.png'}
        ></StaticImage>
        <h2>Our Salon</h2>
        <p>
          Little Hair Lounge welcomes you with warmth and love from the moment
          you step into the salon.
        </p>
        <p>
          We are ready and waiting to start achieving your ultimate hair goals
          today.
        </p>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 p-5">
          <div className="col">
            <div className="card  gallery-background-image salon-1"></div>
          </div>
          <div className="col">
            <div className="card  gallery-background-image salon-2"></div>
          </div>
          <div className="col">
            <div className="card  gallery-background-image salon-3"></div>
          </div>
          <div className="col">
            <div className="card  gallery-background-image salon-4"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Salon
