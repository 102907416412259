import React from 'react'
import Layout from '../layout/Layout'
import Cpr from './components/about/Cpr'
import Josie from './components/about/Josie'
import Salon from './components/about/Salon'
import CustomerTestimonialsCarousel from './components/shared/CustomerTestimonialsCarousel'
import Footer from './components/shared/Footer'
import NavBar from './components/shared/NavBar'

const AboutUsPage = () => {
  return (
    <Layout>
      <NavBar />
      <div className="page-header-background-image page-header-about-background-image">
        <div className="container py-5 text-center">
          <h1 className="p-5">All about us</h1>
        </div>
      </div>
      <div className="mustard-divider"></div>
      <Josie />
      <Salon />
      <Cpr />
      <CustomerTestimonialsCarousel />
      <Footer />
    </Layout>
  )
}

export default AboutUsPage
