import React from 'react'
import BOOKING_LINK from '../../../js/BOOKING_LINK'

const Josie = () => {
  return (
    <div className="position-relative">
      <div className="container-fluid full-width-background-image-container">
        <div className="row h-100">
          <div className="col-md-6 background-image background-josie"></div>
          <div className="col-md-6"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <section className="col-md-6">
            <div className="spacer-background-image-tall"></div>
          </section>
          <section className="col-md-6 p-5">
            <h2>Creative Directior</h2>
            <p>Hello, I'm Josie.</p>
            <p>
              I have been in the hairdressing industry for 15 years and I’m
              still as passionate today as the day I started.
            </p>
            <p>
              I started my training in one of Darwin's top salons with an award
              winning educator. Her passion for hair and wellness and her
              dedication to exceptional training has made me the hairdresser I
              am today.
            </p>
            <p>
              I love educating myself on the latest colouring techniques,
              haircutting and styling trends. I completed all levels of training
              at the HeadMasters Academy London in 2013 and have taken my talent
              all over the world learning new techniques everywhere I went -
              from cutting hair on the beach in South East Asia to top end
              salons in the UK.
            </p>
            <p>
              I always strive to achieve the highest level of hairdressing and
              challenge myself to be the best that I can be. I love all aspects
              of hairdressing, particularly precision haircuts and bobs or
              creating beautiful blondes. Creating tasteful elegance with styles
              you can wash and wear any day, I love to make my clients look and
              feel amazing because your hair really is the crown you never take
              off.
            </p>
            <p>
              I can't wait to welcome you to the salon and start your hair
              transformation.
            </p>
            <a
              href={BOOKING_LINK}
              rel="noreferrer"
              target="_blank"
              className="links"
            >
              Book Your Appointment Now
            </a>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Josie
